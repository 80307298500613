import { forwardRef } from 'preact/compat';

import CheckCircleInput from '~/components/inputs/CheckCircleInput';
import InputLabel from '~/components/inputs/InputLabel';

/**
 * @param {Parameters<CheckCircleInput>} props
 */
const Wrapper = forwardRef((props, ref) => (
  <InputLabel {...props} Input={CheckCircleInput} ref={ref} variant="left" />
));

export default Wrapper;
