/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { forwardRef } from 'preact/compat';
import { useState } from 'preact/hooks';
import IconButton from '~/components/buttons/IconButton';
import cls from '~/helpers/cls';
import './PasswordField.css';

import { VisibilityOffIcon, VisibilityOnIcon } from '../../components/icons';

import Field from '~/components/inputs/Field';
import InputLabel from '~/components/inputs/InputLabel';

const PasswordField = forwardRef(({ noVisibility = false, ...props }, ref) => {
  const { corner, disabled } = props;
  const [visible, setVisible] = useState(false);
  const toggleVisibility = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setVisible(!visible);
  };
  const Icon = visible ? VisibilityOnIcon : VisibilityOffIcon;
  const classes = cls('PasswordField', { corner });
  return (
    <div className={classes}>
      <Field {...props} ref={ref} type={visible ? 'text' : 'password'} />
      {!noVisibility && (
        <IconButton disabled={disabled} onClick={toggleVisibility} size="md">
          <Icon className="PasswordField-icon" />
        </IconButton>
      )}
    </div>
  );
});

const Wrapper = forwardRef((props, ref) => (
  <InputLabel
    {...props}
    className="PasswordField-wrapper"
    Input={PasswordField}
    outline
    ref={ref}
    width="full"
  />
));

export default Wrapper;
