import { forwardRef } from 'preact/compat';

import cls from '~/helpers/cls';
import './CheckCircleInput.css';

import CheckCircleIcon from '~/components/icons/CheckCircleIcon';
import RemoveCircleIcon from '~/components/icons/RemoveCircleIcon';
import CheckCircleOutlinedIcon from '~/components/icons/CheckCircleOutlinedIcon';

const component = 'CheckCircle';

const Icon = (checked, indeterminate) => {
  if (checked) {
    if (indeterminate) return RemoveCircleIcon;
    return CheckCircleIcon;
  }
  return CheckCircleOutlinedIcon;
};

const CheckCircleInput = forwardRef((props, ref) => {
  const {
    checked = false,
    className = undefined,
    disabled = false,
    onlyFade = false,
    id = undefined,
    indeterminate = false,
    onChange = undefined,
    required,
    style = undefined,
  } = props;
  const classes = cls(component, { onlyFade }, className);
  const SVG = Icon(checked, indeterminate);
  return (
    <div className="CheckCircleInput">
      <input
        checked={checked}
        className={classes}
        disabled={disabled}
        id={id}
        onChange={onChange}
        ref={ref}
        required={required}
        style={style}
        type="checkbox"
      />
      <SVG />
    </div>
  );
});

export default CheckCircleInput;
