import lazy from 'preact-lazy';

import { isShopifyAccount, isStripeAccount } from '~/schema/account';

const isShopify = ({ account }) => isShopifyAccount(account);
const isStripe = ({ account }) => isStripeAccount(account);

const routes = [
  {
    routeId: 'component-examples',
    title: 'Component Examples',
    path: '/components',
    Component: lazy(() => import('./ComponentExamples')),
  },
  {
    routeId: 'crash-test',
    title: 'Crash Test',
    breadcrumbs: ['home'],
    path: '/crash-test',
    Component: lazy(() => import('./CrashTest')),
  },
  {
    routeId: 'home',
    title: '',
    path: '/',
    Component: lazy(() => import('./Home')),
  },
  {
    routeId: 'account',
    navigate: isStripe,
    title: 'Account Details',
    path: '/account',
    Component: lazy(() => import('./account/Account')),
  },
  {
    routeId: 'billing-history',
    navigate: isStripe,
    title: 'Billing History',
    path: '/billing-history',
    Component: lazy(() => import('./billing-history/BillingHistory')),
  },
  {
    routeId: 'get-started',
    title: 'Get Started',
    path: '/get-started',
    Component: lazy(() => import('./get-started/GetStarted')),
    noLoginNeeded: true,
    standalone: true,
  },
  {
    routeId: 'invite-user',
    title: 'User Invitation',
    path: '/invite/:token',
    Component: lazy(() => import('./invite/Invitation')),
    noLoginNeeded: true,
    standalone: true,
  },
  {
    routeId: 'carriyo-plans',
    title: 'Carriyo Plans',
    path: '/public/plans',
    Component: lazy(() => import('./public/Plans')),
    noLoginNeeded: true,
    standalone: true,
  },
  {
    routeId: 'icon-test',
    title: 'Icon Test',
    path: '/icon-test',
    Component: lazy(() => import('./IconTest')),
  },
  {
    routeId: 'page-not-found',
    title: 'Page Not Found',
    breadcrumbs: ['home'],
    path: '',
    default: true,
    Component: lazy(() => import('./PageNotFound')),
  },
  {
    routeId: 'payment-methods',
    navigate: isStripe,
    title: 'Payment Methods',
    breadcrumbs: ['home'],
    path: '/payment-methods',
    Component: lazy(() => import('./payment-methods/PaymentMethods')),
  },
  {
    routeId: 'add-payment-method',
    title: 'Add Payment Method',
    breadcrumbs: ['/payment-methods'],
    path: '/payment-methods/add',
    Component: lazy(() => import('./payment-methods/add/AddPaymentMethod')),
  },
  {
    routeId: 'subscription',
    navigate: isStripe,
    title: 'Subscription',
    breadcrumbs: ['home'],
    path: '/subscription',
    Component: lazy(() => import('./subscription/Subscription')),
  },
  {
    routeId: 'subscription-change',
    title: 'Change Subscription',
    breadcrumbs: ['subscription'],
    path: '/subscription/change',
    standalone: true,
    Component: lazy(() => import('./subscription/SubscriptionChange')),
  },
  {
    routeId: 'subscription-cancel',
    title: 'Cancel Subscription',
    breadcrumbs: ['subscription'],
    path: '/subscription/cancel',
    standalone: true,
    Component: lazy(() => import('./subscription/cancel/SubscriptionCancel')),
  },
  {
    routeId: 'subscription-schedule',
    title: 'Subscription Schedule',
    breadcrumbs: ['subscription'],
    path: '/subscription/schedule',
    standalone: true,
    Component: lazy(() =>
      import('./subscription/SubscriptionSchedule')
    ),
  },
  {
    routeId: 'shopify-get-started',
    title: 'Integrate With Carriyo',
    breadcrumbs: [],
    path: '/shopify/get-started',
    noLoginNeeded: true,
    standalone: true,
    Component: lazy(() => import('./shopify/get-started/GetStarted')),
  },
  {
    routeId: 'shopify-account',
    title: 'Account Details',
    breadcrumbs: [],
    navigate: isShopify,
    path: '/shopify/account',
    Component: lazy(() => import('./shopify/account/Account')),
  },
  {
    routeId: 'shopify-subscription',
    title: 'Subscription',
    breadcrumbs: ['home'],
    navigate: isShopify,
    path: '/shopify/subscription',
    Component: lazy(() => import('./shopify/subscription/Subscription')),
  },
  {
    routeId: 'shopify-subscription-change',
    title: 'Change Subscription',
    breadcrumbs: ['shopify-subscription'],
    path: '/shopify/subscription/change',
    standalone: true,
    Component: lazy(() => import('./shopify/subscription/SubscriptionChange')),
  },
  {
    routeId: 'shopify-subscription-verify',
    title: 'Verify Subscription',
    breadcrumbs: ['shopify-subscription'],
    path: '/shopify/subscription/verify',
    standalone: true,
    Component: lazy(() => import('./shopify/subscription/SubscriptionVerify')),
  },
  {
    routeId: 'shopify-subscription-schedule',
    title: 'Subscription Schedule',
    breadcrumbs: ['shopify-subscription'],
    path: '/shopify/subscription/schedule',
    standalone: true,
    Component: lazy(() =>
      import('./shopify/subscription/SubscriptionSchedule')
    ),
  },
];

export default routes;
