import { useEffect } from 'preact/hooks';
import { Router } from 'preact-router';
import Redirect from './Redirect';

// -- custom --
import { useGlobalState } from '../store';

import { replace } from '~/actions/route';

import Layout from './Layout';
import routes from '../routes/routes';
// import { getPermissionsForRoute } from '../../permissions';
import redirects from './redirects';
// import * as routeActions from '../../actions/route';

const RouteComponent = (props) => {
  const { account, route, url, matches } = props;

  const { accountId: postLoginAccountId, externalBilling } = account;

  const title = route.title.replace(
    /:([^\b]+)/g,
    (m, name) => matches?.[name] ?? m
  );

  useEffect(() => {
    if (postLoginAccountId) {
      const isShopifyRoute = url.startsWith('/shopify/');
      if (externalBilling?.startsWith('shopify~')) {
        if (!isShopifyRoute) {
          replace('/shopify/account');
        }
      } else if (isShopifyRoute) {
        replace('/account');
      }
    }
  }, [externalBilling, postLoginAccountId]);

  useEffect(() => {
    document.title = ['Account Management', title].join(' | ');
  }, []);

  return (
    <Layout
      account={account}
      component={route.Component}
      mobileComponent={route.MobileComponent}
      route={{
        ...route,
        url,
        matches,
        title,
      }}
    />
  );
};

const WrappedRouter = () => {
  const account = useGlobalState('account');
  return (
    <Router>
      {redirects.map(({ id, path, to }) => (
        <Redirect key={`redirects-${id}`} path={path} to={to} />
      ))}
      {routes.map((route) => (
        <RouteComponent
          account={account}
          key={route.path}
          path={route.path}
          route={route}
          default={route.default}
        />
      ))}
    </Router>
  );
};

export default WrappedRouter;
