import { forwardRef } from 'preact/compat';

import Field from '~/components/inputs/Field';
import InputLabel from '~/components/inputs/InputLabel';

const TextField = forwardRef((props, ref) => (
  <Field {...props} ref={ref} type="text" />
));

/*
 * @param {Parameters<Field>} props
 */
const Wrapper = forwardRef((props, ref) => (
  <InputLabel width="full" {...props} Input={TextField} outline ref={ref} />
));

export default Wrapper;
